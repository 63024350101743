import { useMemoize } from '@vueuse/core'
import { http, useVzFetch } from '~/composables/useVzFetch'
import {UrlDirection} from "~/types/types";

export function getDirectionsMostPopular(isMainPage: boolean): Promise<PublicDirection[]> {
  return useVzFetch(
      `${http}/seo/best-directions/?isMainPage=${isMainPage}`,
      { method: 'get', credentials: 'include' }
  ) as Promise<PublicDirection[]>
}

export const getDirectionsPage = useMemoize(
  async (params) => {
    const result = await $fetch.raw(
      `${http}/directions/`,
      { method: 'get', params }
    )
    return {
      data: result?._data,
      currentPage: +(result?.headers?.get('X-Pagination-Current-Page') || 1),
      pages: +(result?.headers?.get('X-Pagination-Page-Count') || 1)
    }
  }
)

export function getDirectionsDistance(url: string): Promise<UrlDirection[]> {
  return useVzFetch(
    `${http}/directions?url=${url}&fields=url,distance&expand=price`,
    { method: 'post', credentials: 'include' }
  )
}
